import React from "react";

import { ProdutoresContainer } from "./styles";
import api from "../../services/api";
import { Container } from "react-grid-system";
import CardGeral from "../../components/card-geral/index";
import GenericUpper from "../../components/generic-upper";
import Table from "../../components/table";
import TBody from "../../components/tbody";
import Button from "../../components/button";
import DotsLoader from "../../components/loader";
import InputSearch from "../../components/input-search-documentos";
import ModalDocumentos from "../../components/modal/modalDocumento";
import ModalFuncaoBloqueada from '../../components/modal/modalFuncaoBloqueada';
import ModalTelefone from '../../components/modal/modalCadTelefoneEmergencia';
import ModalLimiteImpressao from '../../components/modal/modalFuncaoBloqueadaImpressaoFe';

import { store } from "react-notifications-component";

export const USER = "@userinfo";

class DocSeguranca extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            documentos: [],
            textfile: [],
            limit: 5,
            loading: false,
            hideButton: false,
            offset: 0,
            showmodal: false,
            modalClose: false,
            result: '',
            newfichas: [],
            fichas: [],
            fichasToFind: [],
            size_screen: window.screen.width,
            fichasTotais: [],
            iframe: "",
            campo_busca: "",
            value_select: "*",
            tempoPesquisa: true,
            modalTesteGratis: false,
            modalTelefone: false,
            modalLimiteImpressao: false,
            modalText: "",
            user: [],
            loaded: true,
            tipoLoja: '',
            loadingFE: false,
            loadingPDF: false,
        };
    }


    componentDidMount() {
        this.setState({ loading: true })
        setInterval(async () => {
            this.setState({ size_screen: window.screen.width });
        }, 990);
        const user = JSON.parse(localStorage.getItem("@userinfo"));
        this.setState({ user });
        this.voltaNormal();
        this.setState({ loading: false })
    };


    //---------------------------->
    formatFichasEmergencia = fe => {
        if (fe !== undefined) {
            fe = fe.map(fe => {
                const format = {
                    id: fe.id,
                    nome_produto: fe.nome_comercial ? fe.nome_comercial : fe.nome_produto,
                    manufacturer: { nome: fe.fabricante },
                    data_atualizacao: fe.data_atualizacao,
                    tipo: fe.tipo
                };
    
                return format;
            })
            return fe;
        }
    };

    formatFichas = fe => {
        fe = fe.map(fe => {
            const format = {
                id: fe.id,
                nome_produto: fe.nome_comercial ? fe.nome_comercial : fe.nome_produto,
                manufacturer: {nome: fe.manufacturer.nome},
                data_atualizacao: fe.data_atualizacao_fabricante ? fe.data_atualizacao_fabricante.substr(0, 10) : null,
                tipo: fe.tipo
            };

            return format;
        })
        return fe;
    };

    formatAllDocumentsForFISPQ = fe => {

        let newFE = []
        fe.map(fe => {
            if (fe.tipo === 'FQ') {
                const format = {
                    id: fe.id,
                    nome_produto: fe.nome_produto,
                    manufacturer: {
                        nome: fe.fabricante
                    },
                    data_atualizacao: fe.data_atualizacao,
                    tipo: fe.tipo
                };
                
                newFE = [...newFE, format];
            }
        })

        return newFE;
    };
    formatAllDocumentsForFE = fe => {

        let newFE = []
        fe.map(fe => {
            if (fe.tipo === 'FE') {
                const format = {
                    id: fe.id,
                    nome_produto: fe.nome_produto,
                    manufacturer: {
                        nome: fe.fabricante
                    },
                    data_atualizacao: fe.data_atualizacao,
                    tipo: fe.tipo
                };
                
                newFE = [...newFE, format];
            }
        })

        return newFE;
    };
    formatAllDocuments = fe => {
        fe = fe.map(fe => {
            const format = {
                id: fe.id,
                nome_produto: fe.nome_produto,
                manufacturer: {
                    nome: fe.fabricante
                },
                data_atualizacao: fe.data_atualizacao,
                tipo: fe.tipo
            };

            return format;
        })
        return fe;
    };

    formatFISPQS = fq => {

        let format = [];

        fq.map(f => {
            if(f.nome_comercial != "" && f.manufacturer && f.data_atualizacao_fabricante && f.tipo){
                let i = {
                    id: f.id,
                    nome_produto: f.nome_comercial,
                    manufacturer: f.manufacturer,
                    data_atualizacao: f.data_atualizacao_fabricante.substr(0, 10),
                    tipo: f.tipo
                }
                format = [...format, i];
            }else{
            }
            // return format;
        })

        return format;
    };

    handleClickStart = e => {
        e.preventDefault();
        this.setState({
            run: true
        });

    };

    loadMore = async () => {

        if(this.state.value_select == "*"){
            this.setState({ loading: true, hideButton: true });
            const { limit, offset, fichas } = this.state;
            const newOffset = limit + offset;
            try {
                const response = await api.get(
                    "/documentos/seguranca?limit=" + limit + "&offset=" + newOffset
                );
                let documentos = response.data.data;
                let FichasConCat = this.formatFichasEmergencia(documentos);
                FichasConCat = fichas.concat(FichasConCat);
                this.setState({
                    fichas: FichasConCat,
                    offset: newOffset,
                    hideButton: false
                });
            } catch (erro) {
                this.setState({ hideButton: true });
    
            }
        }else if(this.state.value_select == "FE"){
            try {
                this.setState({ loading: true, hideButton: true });
                const { limit, offset, fichas } = this.state;
                const newOffset = limit + offset;
                const feFind = await api.get(`/fichas?limit=${limit}&offset=${newOffset}`);
                let fe = feFind.data.data;
                let fichasConCat = this.formatFichas(fe);
                fichasConCat = fichas.concat(fichasConCat);
                this.setState({ hideButton: false, fichas: fichasConCat, offset: newOffset })

            } catch (error) {
               
            }
        }else if(this.state.value_select == "FQ"){
            try {
                this.setState({ loading: true, hideButton: true });
                const { limit, offset, fichas } = this.state;
                const newOffset = limit + offset;
                const fichasfind = await api.get(`/fispq?limit=${limit}&offset=${newOffset}`);
                let fichasFinded = fichasfind.data.data;
                let fichasConCat = this.formatFISPQS(fichasFinded);
                fichasConCat = fichas.concat(fichasConCat);

                this.setState({ hideButton: false, fichas: fichasConCat, offset: newOffset });
                
            } catch (error) {
                
            }

        }
        this.setState({ loading: false });
    };

    searchFild = (value) => {

    };

    openModal = e => {
        this.setState({ showmodal: true });
    };

    modalClose = e => {
        if (this.state.showmodal == true) {
            this.setState({ showmodal: false });
        }

        if (this.state.modalTelefone == true) {
            // this.setState({ modalTelefone: false });
            window.location = '/';
        }

        if (this.state.modalLimiteImpressao == true) {
            // this.setState({ modalLimiteImpressao: false });
            window.location = '/';

        }
    };

    redicttoPDF = async (id) => {
        try {
            if (this.state.loadingPDF === false) {

                this.setState({ loadingPDF: true });
                
                const response = await api.post(`/fispq/${id}`);
                let pdf = response.data.data[0].link;
                window.open(`${response.data.data[0].link}`)

                this.setState({ loadingPDF: false });

            }
        } catch (error) {

            if (error.response.data.errors == "Você atingiu o limite de impressões gratuitas.") {

                this.setState({ modalLimiteImpressao: true });

            } else {
                store.addNotification({
                    title: "Erro!",
                    message: error.response.data.errors ? `${error.response.data.errors}` : 'Tente novamente mais tarde',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        }

    };

    voltaNormal = async () => {
        this.setState({ value_select: "*" });
        const response = await api.get(`/documentos/seguranca?limit=5&offset=0`);
        let documentos = response.data.data;
        documentos = this.formatFichasEmergencia(documentos);
        this.setState({ fichas: documentos })
    };

    update_select = async (value) => {
    };

    updateValues = async (text, isSearch, select) => {
        const {limit, offset} = this.state;

        console.log(text.length)

        if(text.length > 2){
            if (isSearch === 1) {
                if (select === 'FQ') {
                    if (text === '') {
                        try {
                            const fichasfind = await api.get(`/fispq?limit=${limit}&offset=${offset}`);
                            let fichasFinded = fichasfind.data.data;
                            fichasFinded = this.formatFISPQS(fichasFinded)
                            this.setState({ hideButton: false, fichas: fichasFinded })
                        } catch (error) {
                            
                        }
                    } else if (text !== '') {
                        try {
                            await api.get(`/documentos/seguranca?value=${text}`).then(response => {
                                let todosDocumentos = response.data.data;
                                todosDocumentos = this.formatAllDocumentsForFISPQ(todosDocumentos);
                                this.setState({ fichas: todosDocumentos });
                            })                    
                        } catch (error) {
                            
                        }
                    } 
                } else if (select === 'FE') {
                    if (text === '') {
                        try {
                            const feFind = await api.get(`/fichas?limit=${limit}&offset=${offset}`);
                            let fe = feFind.data.data;
                            fe = this.formatFichas(fe);
                            this.setState({ hideButton: false, fichas: fe })
                        } catch (error) {
                           
                        }
                    } else if (text !== '') {
                        try {
                            await api.get(`/documentos/seguranca?value=${text}`).then(response => {
                                let todosDocumentos = response.data.data;
                                todosDocumentos = this.formatAllDocumentsForFE(todosDocumentos);
                                this.setState({ fichas: todosDocumentos });
                            })  
                        } catch (error) {
                            
                        }
                    } 
                } else if (select === '' || select === '*') {
    
                    if (text === '') {
                        await api.get(`/documentos/seguranca?limit=${limit}&offset=${offset}`).then(response => {
                            let todosDocumentos = response.data.data;
                            todosDocumentos = this.formatAllDocuments(todosDocumentos);
                            this.setState({ fichas: todosDocumentos });
                        })  
                    } else {
                        await api.get(`/documentos/seguranca?value=${text}`).then(response => {
                            let todosDocumentos = response.data.data;
                            todosDocumentos = this.formatAllDocuments(todosDocumentos);
                            this.setState({ fichas: todosDocumentos });
                        })  
                    }
                }
            }

        }else if(text.length === 0){
            this.voltaNormal()
        }


        this.setState({ loaded: false });
        this.setState({ limit: 5 });
        this.setState({ offset: 0 });

        const valor = text;
        const tipo = select;

        this.setState({ value_select: tipo })

        this.setState({ loaded: true});
    };

    updateResposta = async (resposta) => {
        if (resposta == 0) {
            this.setState({ showmodal: false })
        }
    };



    downloadFE = async (id) => {
        try {
            if (this.state.loadingFE === false) {

                this.setState({ loadingFE: true });
                
                let expedidor = await api.get(`/expedidor`);
                expedidor = expedidor.data.data.id;
                const response = await api.post(`/ficha/${expedidor}/${id}`);
                window.open(`${response.data.data.link}`);

                this.setState({ loadingFE: false });
            }

        } catch (err) {


            if (err.response.data.errors) {
                if (err.response.data.errors != "Você atingiu o limite de impressões gratuitas.") {
                    this.setState({ modalText: err.response.data.errors });
                    this.setState({ modalTelefone: true });
                    if(err.response.data.data.is_matriz){
                        this.setState({ tipoLoja: err.response.data.is_matriz });
                    }
                } else {

                    if (err.response.data.errors == "Você atingiu o limite de impressões gratuitas.") {

                        this.setState({ modalLimiteImpressao: true });

                    } else {
                        store.addNotification({
                            title: "Erro!",
                            message: err.response.data.errors ? `${err.response.data.errors}` : 'Tente novamente mais tarde',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 5000,
                                onScreen: true
                            }
                        });
                    }
                }
            }
        }
    };

    onKeyDown = (e) => {
        e.preventDefault();
        const { campo_busca } = this.state;
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            this.searchFild(campo_busca);
        }
    };

    render() {
        const {
            showmodal,
            fichas,
            hideButton,
            loading,
            size_screen,
            value_select,
            modalTesteGratis
        } = this.state;
        return (
            <>
                <GenericUpper
                    title="Documentos de Segurança"
                    search={true}
                    searchFunction={(text, isSearch, select) => {this.updateValues(text, 1, select); console.log('textInput', text)}}
                    placeholder="Produto, Fabricante"
                    tooltip={"Solicitar"}
                    docSeguranca={true}
                    valueSelect={value_select}
                    estados={true}
                    addLinkModal = {() => 
                        this.state.user.contract_software_id == 6 ? 
                        this.setState({ modalTesteGratis: true }) : this.setState({showmodal: true})
                    }
                ></GenericUpper>
                <ProdutoresContainer>
                    <Container>
                        {size_screen <= 768 ? (<>
                            <CardGeral
                                titulos={["DOCUMENTO", "NOME DO PRODUTO", "FABRICANTE", "DATA DE ATUALIZAÇÃO", "TIPO"]}
                                valores={fichas}
                            />

                            <DotsLoader show={loading}></DotsLoader>

                            <Button
                                style={{ outline: 0 }}
                                className="loadmore"
                                hide={hideButton}
                                outline
                                onClick={this.loadMore}
                                color="#00b43f"
                            >
                                Ver mais
                            </Button>

                            <ModalDocumentos
                                // style={{ display: "flex", justifyContent: "center" }}
                                show={showmodal}
                                onHide={this.modalClose}
                                resposta=''
                                update={this.updateResposta}
                                title='Solicitar um Documento'
                            ></ModalDocumentos>
                            <ModalFuncaoBloqueada
                                show={modalTesteGratis}
                                onHide={() => {
                                    this.modalClose();
                                    this.setState({ modalTesteGratis: false })
                                }}
                            ></ModalFuncaoBloqueada>
                            <ModalLimiteImpressao
                                show={this.state.modalLimiteImpressao}
                                onHide={this.modalClose}
                            ></ModalLimiteImpressao>
                        </>) : (<>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Nome do Produto</th>
                                        <th>Fabricante</th>
                                        <th>Data de atualização</th>
                                        <th>Tipo</th>

                                    </tr>
                                </thead>
                                <TBody fichas={fichas.length > 0 ? true : false} loaded={this.state.loaded}>
                                    {fichas.map(ficha => {
                                        return (<>
                                            <tr
                                                onClick={() => {
                                                    if (ficha.tipo == "FQ") {
                                                        this.redicttoPDF(ficha.id)
                                                    } else {
                                                        this.downloadFE(ficha.id)
                                                    }
                                                }}
                                                key={ficha.id}
                                            >
                                                <td>{ficha.nome_produto}</td>
                                                <td>{ficha.manufacturer ? ficha.manufacturer.nome : ''}</td>
                                                <td> <div className="art-grid"><label className="loja">{ficha.data_atualizacao ? ficha.data_atualizacao.split('-').reverse().join('/') : "N/D"}</label></div></td>
                                                <td><div className="estado">{ficha.tipo == "FQ" ? "FDS(FISPQ)" : "FE"}</div></td>
                                            </tr>
                                        </>)
                                    })}
                                </TBody>
                            </Table>

                            <DotsLoader show={loading}></DotsLoader>
                            <Button
                                style={{ outline: 0 }}
                                className="loadmore"
                                hide={hideButton}
                                outline
                                onClick={this.loadMore}
                                color="#00b43f"
                            >
                                Ver mais
                            </Button>

                            <ModalDocumentos
                                // style={{ display: "flex", justifyContent: "center" }}
                                show={showmodal}
                                onHide={this.modalClose}
                                resposta=''
                                update={this.updateResposta}
                                title='Solicitar um Documento'
                            ></ModalDocumentos>

                            <ModalFuncaoBloqueada
                                show={modalTesteGratis}
                                onHide={() => {
                                    this.modalClose();
                                    this.setState({ modalTesteGratis: false })
                                }}
                            ></ModalFuncaoBloqueada>
                            
                            <ModalTelefone
                                title="Aviso!"
                                text={this.state.modalText}
                                show={this.state.modalTelefone}
                                tipoLoja={this.state.tipoLoja}
                                onHide={this.modalClose}
                            >

                            </ModalTelefone>

                            <ModalLimiteImpressao
                                show={this.state.modalLimiteImpressao}
                                onHide={this.modalClose}
                            >

                            </ModalLimiteImpressao>
                        </>)}
                    </Container>
                </ProdutoresContainer>
            </>
        );
    }
}
export default DocSeguranca;