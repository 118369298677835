import React, { useState, useEffect, useCallback } from 'react';
import { Icon, Content, TitleWel, FundoSt1, Email } from './styles';
import Welcome from '../../assets/tour/undraw_welcoming.svg';
import Seta from '../../assets/tour/undraw_arrow.svg';
import Step02 from './step02';
import logoBranco from '../../assets/tour/logoIcone_branco.svg';
import { ImgFundo } from '../../pages/tour/styles';

const Step01 = ({ ...props }) => {

    const email = localStorage.getItem('@agroreceita_email_sub');
    return (
        <>
            <Content marginLeft={props.steps}>
                <FundoSt1>
                    <TitleWel>
                        Bem vindo(a) ao<br />
                        AgroReceita! 🙂
                    </TitleWel>

                    <Icon>
                        <img src={Welcome}/>
                    </Icon>
                    <Email>{email}</Email>
                </FundoSt1>
                <ImgFundo>
                    <img src={logoBranco} />
                </ImgFundo>
            </Content>
        </>
    );
};

export default Step01;
