import React from "react";
import lupa from "../../assets/icons/icons-8-search.png";
import InputMask from "inputmask";

import { InputContainer } from "./styles";

class InputSearch extends React.Component {

    documentos = [
        { value: '', label: "Documentos" },
        { value: 'FQ', label: "FDS(FISPQ)"},
        { value: 'FE', label: "FE"}
    ];

    estados = [
        { value: "", label: "ESTADOS" },
        { value: "SP", label: "SP" },
        { value: "RJ", label: "RJ" },
        { value: "SC", label: "SC" },
        { value: "AC", label: "AC" },
        { value: "AL", label: "AL" },
        { value: "AP", label: "AP" },
        { value: "AM", label: "AM" },
        { value: "BA", label: "BA" },
        { value: "CE", label: "CE" },
        { value: "DF", label: "DF" },
        { value: "ES", label: "ES" },
        { value: "GO", label: "GO" },
        { value: "MA", label: "MA" },
        { value: "MT", label: "MT" },
        { value: "MS", label: "MS" },
        { value: "MG", label: "MG" },
        { value: "PA", label: "PA" },
        { value: "PB", label: "PB" },
        { value: "PR", label: "PR" },
        { value: "PE", label: "PE" },
        { value: "PI", label: "PI" },
        { value: "RN", label: "RN" },
        { value: "RS", label: "RS" },
        { value: "RO", label: "RO" },
        { value: "RR", label: "RR" },
        { value: "SE", label: "SE" },
        { value: "TO", label: "TO" }
    ];

    estadosArt = [
        { value: "", label: "TODOS OS ESTADOS" },
        { value: "NACIONAL", label: "NACIONAL"},
        { value: "SP", label: "SP" },
        { value: "SC", label: "SC" },
        { value: "AC", label: "AC" },
        { value: "AL", label: "AL" },
        { value: "AP", label: "AP" },
        { value: "AM", label: "AM" },
        { value: "BA", label: "BA" },
        { value: "CE", label: "CE" },
        { value: "DF", label: "DF" },
        { value: "ES", label: "ES" },
        { value: "GO", label: "GO" },
        { value: "MA", label: "MA" },
        { value: "MT", label: "MT" },
        { value: "MS", label: "MS" },
        { value: "MG", label: "MG" },
        { value: "PA", label: "PA" },
        { value: "PB", label: "PB" },
        { value: "PR", label: "PR" },
        { value: "PE", label: "PE" },
        { value: "PI", label: "PI" },
        { value: "RN", label: "RN" },
        { value: "RS", label: "RS" },
        { value: "RO", label: "RO" },
        { value: "RR", label: "RR" },
        { value: "SE", label: "SE" },
        { value: "TO", label: "TO" }
    ];

    estadosNacional = [
        { value: "", label: "TODOS OS ESTADOS" },
        { value: "NACIONAL", label: "NACIONAL"},
        { value: "SP", label: "SP" },
        { value: "RJ", label: "RJ" },
        { value: "SC", label: "SC" },
        { value: "AC", label: "AC" },
        { value: "AL", label: "AL" },
        { value: "AP", label: "AP" },
        { value: "AM", label: "AM" },
        { value: "BA", label: "BA" },
        { value: "CE", label: "CE" },
        { value: "DF", label: "DF" },
        { value: "ES", label: "ES" },
        { value: "GO", label: "GO" },
        { value: "MA", label: "MA" },
        { value: "MT", label: "MT" },
        { value: "MS", label: "MS" },
        { value: "MG", label: "MG" },
        { value: "PA", label: "PA" },
        { value: "PB", label: "PB" },
        { value: "PR", label: "PR" },
        { value: "PE", label: "PE" },
        { value: "PI", label: "PI" },
        { value: "RN", label: "RN" },
        { value: "RS", label: "RS" },
        { value: "RO", label: "RO" },
        { value: "RR", label: "RR" },
        { value: "SE", label: "SE" },
        { value: "TO", label: "TO" }
    ];

    state = {
        ref: React.createRef(),
        estado: '',
        documento: '',
        valor: ''
    };

    componentDidMount() {
        const { ref } = this.state;

        let input = ref.current;

        let im = new InputMask(this.props.mask, { showMaskOnHover: false });

        im.mask(input);
    }

    search = () => {
        let search = 1;
        let text = this.state.valor;        
        let estado = this.state.estado;
        let documento = this.state.documento;
        
        this.props.update(text, estado, search);
        if(this.props.docSeguranca){
            this.props.update(text, search, documento);
        }
    }
    validateInput = async (e) => {
        const valor = e;
        this.setState({ valor }, () => {
            if (this.props.docSeguranca) {
                this.props.update(this.state.valor, 0, this.state.documento);
            } else {
                this.props.update(this.state.valor, this.state.estado, 0);
            }
        });
    };

    getValue = (e) => {
        this.setState({ estado: e });
        this.props.update(this.state.valor, this.state.estado, 0);

    }

    getValue2 = (e) => {
        this.setState({ documento: e });
        let documento = e;
        this.props.update(this.state.valor, 0, documento);
    }

    deleteValue = () => {
        this.setState({valor: ''})
        this.props.update('', this.state.estado, 0)
        this.props.onDelete()
    }

    render() {
        const { ref } = this.state;
        return (

            <InputContainer estado={this.props.selectEstado} documento={this.props.docSeguranca} pesquisa={this.props.pesquisa}>
                {this.props.selectEstado == true ? (<>

                    <div className="inputs">
                        <input ref={ref} {...this.props} value={this.state.valor} onChange={e => this.validateInput( e.target.value)}></input>
                        
                        {this.props.docSeguranca == true ? (<>
                            <select onChange={(e => this.getValue2(e.target.value))}>
                                {this.documentos.map(e => (
                                    <option id="value" key={e.value} value={e.value}> {e.label} </option>
                                ))}
                            </select>
                        </>):(<>
                            <select onChange={(e => this.getValue(e.target.value))}>
                                {this.props.estadoArt == true ? (<>
                                    {this.estadosArt.map(e => (
                                        <option id="value" key={e.value} value={e.value}> {e.label} </option>
                                    ))}
                                </>):(<>
                                    {this.props.estadoNacional == true ? (<>
                                        {this.estadosNacional.map(e => (
                                            <option id="value" key={e.value} value={e.value}> {e.label} </option>
                                        ))}
                                    </>) : (<>
                                        {this.estados.map(e => (
                                            <option id="value" key={e.value} value={e.value}> {e.label} </option>
                                        ))}
                                    </>)}
                                </>)}
                            </select>
                        </>)}

                    </div>


                    {this.props.isDelete === true && this.state.valor && (
                        <p className="xis" onClick={()=>this.deleteValue()}>x</p>
                    )}
                    <div className="search-button">
                        <img src={lupa} onClick={() => this.search()}></img>
                    </div>
                </>) : (<>
                    <div className="inputs">
                        <input ref={ref} {...this.props} value={this.state.valor} onChange={e => this.validateInput(e.target.value)}></input>
                    </div>
                    {this.props.isDelete === true && this.state.valor && (
                        <p className="xis" onClick={()=>this.deleteValue()}>x</p>
                    )}
                    <div className="search-button">
                        <img src={lupa} onClick={() => this.search()}></img>
                    </div>
                </>)}


            </InputContainer>
        );
    }
}

export default InputSearch;
